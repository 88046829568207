<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showOutput="true" @getList="getList"
			@clearSearch="clearSearch">
			<!--搜索自定义内容-->
			<!--      <el-form-item slot="search-item">-->
			<!--        <el-select-->
			<!--          v-model="searchFormData.device_id"-->
			<!--          filterable-->
			<!--          remote-->
			<!--          reserve-keyword-->
			<!--          clearable-->
			<!--          size="small"-->
			<!--          placeholder="按设备名称查询"-->
			<!--          :remote-method="remoteDevice"-->
			<!--          :loading="s2Loading"-->
			<!--        >-->
			<!--          <el-option-->
			<!--            v-for="item in sDevice"-->
			<!--            :key="item.device_id"-->
			<!--            :label="item.device_name"-->
			<!--            :value="item.device_id"-->
			<!--          >-->
			<!--          </el-option>-->
			<!--        </el-select>-->
			<!--      </el-form-item>-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.order_no" size="small" clearable placeholder="按订单号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.phone" size="small" clearable placeholder="按手机号查询"></el-input>
			</el-form-item>
			<!--el-form-item slot="search-item">
				<el-input v-model="searchFormData.remark" size="small" clearable placeholder="按商户号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.uid" filterable remote reserve-keyword clearable size="small"
					placeholder="按用户名查询" :remote-method="remoteFans" :loading="sLoading">
					<el-option v-for="item in sFans" :key="item.fans_id" :label="item.nickname" :value="item.fans_id">
					</el-option>
				</el-select>
			</el-form-item-->
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.order_status" placeholder="按支付状态查询" clearable size="small">
					<el-option label="未支付" :value="10">
					</el-option>
					<el-option label="已支付" :value="20">
					</el-option>
					<el-option label="已取消" :value="30">
					</el-option>
					<el-option label="已退款" :value="40">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.order_ly" placeholder="按支付方式查询" clearable size="small">
					<el-option v-for="(item,index) in paySourceList" :label="item" :value="index" :key="index"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker v-model="searchFormData.pay_time" style="width: 240px;" clearable type="daterange" format="yyyy-MM-dd"
					value-format="yyyy-MM-dd" start-placeholder="开始支付时间" end-placeholder="结束支付时间" size="small">
				</el-date-picker>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="order_no" title="订单号" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="u_type_text" title="用户信息" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span v-if="row.u_type==10">{{row.phone||''}}</span>
					<span v-if="row.u_type==20">电卡-{{row.cardno}}</span>
					<span v-if="row.u_type==30">{{row.phone||''}}</span>
					<span v-if="row.u_type==40">{{row.phone||''}}</span>
					<span v-if="row.u_type==50">{{row.phone||''}}</span>
					<span v-if="row.u_type==60">{{row.phone||''}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="userCityText" title="用户来源" align="center" min-width="100px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="device_id_text" title="充值设备" align="center" min-width="200px">
				<template v-slot="{ row }">
					<div v-if="!row.device_id">无</div>
					<div v-if="row.device_id">{{row.device_name}}</div>
					<div class="vxe-sub" v-if="row.device_id">编号:{{row.device_no}}</div>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="order_price" title="充值金额" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="pay_price" title="实际支付金额" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="gift_money" title="赠送金额" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="pay_status_text" title="支付状态" align="center" min-width="80px">
				<template v-slot="{ row }">
					<div>{{types.pay_status[row.pay_status]||'未知状态'}}</div>
					<div v-if="row.pay_status==40">{{row.refund_price}}元</div>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="pay_time" title="付款时间" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="order_ly_text" title="支付方式" align="center" min-width="100px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="remark" title="备注" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="spread_name" title="来源" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" title="操作" align="center" width="180">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detailOne(row)" v-if="$hasAccess('user.recharge/view')">详情
					</el-button>
					<el-button size="small" plain @click="refundOne(row)"
						v-if="row.pay_status==20&&$hasAccess('user.recharge/refund')">退款</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 详情 -->
		<el-dialog title="充值详情" :visible.sync="detailDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="800px">
			<el-form ref="detailForm" label-width="140px" class="form" size="mini">
				<el-row>
					<el-col :xs="{span:24,offset:0}" :sm="{span:10,offset:1}">
						<!-- 订单信息 -->
						<div class="el-block">
							<div class="row-title">订单信息</div>
							<el-form-item label="订单号：">{{detailDialogFormData.order_no}}</el-form-item>
							<el-form-item label="订单状态：">{{types.pay_status[detailDialogFormData.pay_status]||'未知状态'}}
							</el-form-item>
							<el-form-item label="充值用户：">
								{{detailDialogFormData.u_type==20?'电卡-'+detailDialogFormData.cardno:'微信-'+(detailDialogFormData.nickname||'匿名用户')}}
							</el-form-item>
							<el-form-item label="充值金额：">{{detailDialogFormData.order_price}}元</el-form-item>
							<el-form-item label="实际支付金额：">{{detailDialogFormData.pay_price}}元</el-form-item>
							<el-form-item label="支付时间：">
								{{detailDialogFormData.pay_status==20?detailDialogFormData.pay_time:'未支付'}}
							</el-form-item>
							<el-form-item label="账户余额：">{{detailDialogUserBalance}}元</el-form-item>
							<el-form-item v-if="detailDialogFormData.pay_status==40" label="退款时间：">
								{{detailDialogFormData.refund_time}}</el-form-item>
							<el-form-item v-if="detailDialogFormData.pay_status==40" label="退款金额：">
								{{detailDialogFormData.refund_price}}元</el-form-item>
							<el-form-item v-if="detailDialogFormData.order_ly==62" label="数币优惠金额：">
								{{detailDialogFormData.discount_price}}元</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{span:24,offset:0}" :sm="{span:10,offset:2}">
						<!-- 设备信息 -->
						<div class="el-block">
							<div class="row-title">设备信息</div>
							<el-form-item label="设备名称：">
								{{detailDialogFormData.device_name}}<br />{{detailDialogFormData.device_no}}
							</el-form-item>
						</div>
						<!-- 优惠信息 -->
						<div class="el-block">
							<div class="row-title">优惠信息</div>
							<el-form-item label="充值优惠ID：">{{detailDialogFormData.plan_id}}</el-form-item>
							<el-form-item label="赠送金额：">{{detailDialogFormData.gift_money}}元</el-form-item>
						</div>
						<!-- 代理信息 -->
						<div class="el-block">
							<div class="row-title">代理信息</div>
							<el-form-item label="代理商ID：">{{detailDialogFormData.agent_id||'无'}}</el-form-item>
							<el-form-item label="分销金额：">{{detailDialogFormData.share_price}}元</el-form-item>
							<el-form-item label="分销结算状态：">
								{{types.share_status[detailDialogFormData.share_status]||'未知状态'}}</el-form-item>
						</div>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 退款 -->
		<el-dialog title="充值退款" :visible.sync="refundDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="400px">
			<el-form :model="refundDialogFormData" :rules="refundRules" size="mini" ref="refundForm" label-width="120px"
				class="form">
				<el-form-item label="订单号：">{{refundDialogFormOriData.order_no}}</el-form-item>
				<el-form-item label="实际付款金额：">{{refundDialogFormOriData.pay_price}}元</el-form-item>
				<el-form-item label="退款金额：" prop="refund_price">
					<el-input-number v-model="refundDialogFormData.refund_price" size="small" style="width: 80%;"
						controls-position="right" :min="0.01" :max="refundDialogFormOriData.pay_price" :step="1"
						:precision="2"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveRefund()"
					v-if="refundDialogFormOriData.pay_status==20&&$hasAccess('user.recharge/refund')">确 认 退 款
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'user-recharge-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '充值列表',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {
					//u_type: 10
				},
				types: {
					pay_status: {
						10: '未支付',
						20: '已支付',
						30: '已取消',
						40: '已退款'
					},
					share_status: {
						10: '不结算',
						20: '未结算',
						30: '已结算'
					}
				},
				// 用户搜索
				sLoading: false,
				sFans: [],
				// 设备搜索
				s2Loading: false,
				sDevice: [],
				// 详情
				detailDialogShow: false,
				detailDialogFormData: {},
				detailDialogUserBalance: 0,
				// 退款
				refundDialogShow: false,
				refundDialogFormData: {},
				refundDialogFormOriData: {},
				refundRules: {
					refund_price: [{
						required: true,
						message: '请填写退款金额',
						trigger: 'change'
					}]
				},
				//支付方式列表
				paySourceList:{},
			}
		},
		mounted: function(){
			this.paySourceList = Object.assign({}, this.$paySource);
			delete this.paySourceList[60];
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				// 小区限制
				const group_id = this.$store.state.user.userInfo.group_id
				if (!searchData.group_id && group_id) {
					searchData.group_id = group_id
				}
				const res = await this.$api.Fans.GetRechargeList(params, searchData)
				this.tableData = res.data;
				for(let v of this.tableData){
					if(v.u_type == 20){
						v.u_type_text = '电卡-' + v.cardno;
					}else{
						v.u_type_text = v.phone || '';
					}
					v.userCityText = this.$userArea[v.userCity]||v.userCity;
					if(!v.device_id){
						v.device_id_text = '无';
					}else if(v.device_id){
						v.device_id_text = v.device_name;
					}else{
						v.device_id_text = '编号:' + v.device_no;
					}
					v.pay_status_text = this.types.pay_status[v.pay_status] || '未知状态';
					if(v.pay_status == 40){
						v.pay_status_text += ' ' + v.refund_price + '元';
					}
					v.order_ly_text = this.$paySource[v.order_ly];
				}
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {
					//u_type: 10
				}
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false
				this.refundDialogShow = false
			},
			// 详情
			async detailOne(row) {
				const params = {
					token: this.$store.state.user.token,
					order_id: row.order_id
				}
				const res = await this.$api.Fans.GetRechargeDetail(params)
				this.detailDialogFormData = res
				await this.detailUserOne(res.fans_id)
				this.detailDialogShow = true
			},
			// 用户详情
			async detailUserOne(id) {
				const params = {
					token: this.$store.state.user.token,
					fans_id: id
				}
				const res = await this.$api.Fans.GetFansDetail(params)
				this.detailDialogUserBalance = res.balance
			},
			// 退款
			refundOne(row) {
				this.refundDialogFormData = {
					order_id: row.order_id,
					refund_price: row.pay_price
				}
				this.refundDialogFormOriData = {
					pay_price: Number(row.pay_price),
					pay_status: row.pay_status,
					order_no: row.order_no
				}
				this.refundDialogShow = true
			},
			// 退款保存
			saveRefund() {
				this.$refs.refundForm.validate(async valid => {
					if (valid) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.refundDialogFormData)
						}
						await this.$api.Fans.RefundRecharge(params)
						this.$notify({
							title: '成功',
							message: '退款成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 用户名查询
			async remoteFans(query) {
				if (query !== '') {
					this.sLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const searchData = {
						nickname: query
					}
					const res = await this.$api.Fans.GetFansList(params, searchData)
					this.sLoading = false
					this.sFans = res.data
				} else {
					this.sFans = []
				}
			},
			// 设备名查询
			async remoteDevice(query) {
				if (query !== '') {
					this.s2Loading = true
					const params = {
						token: this.$store.state.user.token
					}
					const searchData = {
						device_name: query
					}
					const res = await this.$api.Device.GetDeviceList(params, searchData)
					this.s2Loading = false
					this.sDevice = res.data
				} else {
					this.sDevice = []
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
